<template>
  <div>
    <div
      v-for="product in orderProducts"
      :key="product.productId"
    >
      <product-details
        :product="product"
        :shipment-list="shipmentList"
        :is-product-expanded="isExpandedProduct[product.productId]"
        :class="isExpandedProduct[product.productId] ? 'expanded-product-margin' : 'product-margin'"
        @on-click-expand="expandProduct(product)"
        @on-style-change-cancelled="$emit(STYLE_STATUS_CHANGE_CANCELLED, $event)"
        @on-style-assign-shipment="$emit(STYLE_ASSIGN_TO_SHIPMENT, $event)"
        @load-shipment="$emit(LOAD_SHIPMENT)"
        @update-total="updateTotal"
      />
      <b-collapse
        :visible="isExpandedProduct[product.productId]"
      >
        <product-colors
          :product="product"
          @on-click-expand="expandProduct(product)"
          @update-total="updateTotal"
        />
      </b-collapse>
    </div>
    <k-table-pagination
      v-if="enablePagination"
      :total-items="productCount"
      @emit-current-page="updateCurrentPage"
      @emit-per-page="updatePerPage"
    />
  </div>
</template>

<script>
import { VBToggle, BCollapse } from 'bootstrap-vue'
import Vue, { ref, reactive } from 'vue'
import { KTablePagination } from '@kingpin-global/kingpin-ui'
import { getFilteredProducts } from '../order-management/order-management-utils'
import ProductDetails from './ProductDetails.vue'
import ProductColors from './product-information/ProductColors.vue'
import {
  getColors,
  STYLE_STATUS_CHANGE_CANCELLED,
  STYLE_ASSIGN_TO_SHIPMENT,
  LOAD_SHIPMENT,
} from './product-utils'

export default {
  name: 'Products',
  components: {
    ProductDetails, ProductColors, BCollapse, KTablePagination,
  },
  directives: {
    'v-b-toggle': VBToggle,
  },
  props: {
    products: {
      type: Array,
      default() {
        return []
      },
    },
    enablePagination: {
      type: Boolean,
      default: false,
    },
    shipmentList: {
      type: Array,
      default() {
        return []
      },
    },
    updateOrderTotal: {
      type: Function,
      default() {
        return () => {}
      },
    },
  },
  watch: {
    products() {
      this.setOrderProducts()
    },
  },
  created() {
    // for listen the store value
    this.$store.watch(
      () => this.$store.getters.getIsExpandedAllProducts,
      newValue => {
        this.setExpandAll(newValue)
      },
    )
    this.updateTotal()
  },
  methods: {
    updateTotal() {
      this.$emit('total-value')
    },
  },
  emits: [STYLE_STATUS_CHANGE_CANCELLED, STYLE_ASSIGN_TO_SHIPMENT, 'total-value'],
  setup(props) {
    const orderProducts = ref([])
    const searchResults = ref([])
    const isExpandedProduct = reactive({})
    const productCount = ref(0)
    const perPage = ref(10)
    const page = ref(1)

    const updateCurrentPage = pageNumber => {
      page.value = pageNumber
      orderProducts.value = getFilteredProducts(searchResults.value, page.value, perPage.value)
    }

    const updatePerPage = count => {
      perPage.value = count
      orderProducts.value = getFilteredProducts(searchResults.value, page.value, perPage.value)
      productCount.value = props.products.length
    }

    const setOrderProducts = () => {
      const products = []
      props.products.forEach(product => {
        Vue.set(isExpandedProduct, product.productId, false)
        const colorObj = getColors(product)
        product.colorsCount = colorObj.count
        const productColors = []
        const colors = []
        product.items.forEach(item => {
          if (colors.indexOf(item.color) < 0) {
            colors.push(item.color)
            productColors.push({ color: item.color, colorFamily: item.colorFamily })
          }
        })
        product.colors = productColors
        products.push(product)
      })
      searchResults.value = props.products
      orderProducts.value = props.products
      if (props.enablePagination) {
        productCount.value = props.products.length
        updatePerPage(perPage.value)
      }
    }
    setOrderProducts()

    const expandProduct = product => {
      Vue.set(
        isExpandedProduct,
        product.productId,
        !isExpandedProduct[product.productId],
      )
    }

    const setExpandAll = (val = false) => {
      orderProducts.value.forEach(product => {
        Vue.set(isExpandedProduct, product.productId, val)
      })
    }

    return {
      productCount,
      perPage,
      page,
      searchResults,
      orderProducts,
      isExpandedProduct,
      expandProduct,
      setOrderProducts,
      setExpandAll,
      updateCurrentPage,
      updatePerPage,
      STYLE_STATUS_CHANGE_CANCELLED,
      STYLE_ASSIGN_TO_SHIPMENT,
      LOAD_SHIPMENT,
    }
  },
}
</script>

<style lang="scss" scoped>
.product-margin {
  margin-bottom: 16px;
}

.expanded-product-margin {
  margin-bottom: 8px;
}
</style>
