<template>
  <div class="d-flex justify-content-between order-product-search-bar-section">
    <!-- Search bar -->
    <k-search-bar-section
      placeholder="Search by Style Code, Unique ID and Style Name"
      hide-assign-option
      hide-edit-option
      hide-delete-option
      hide-selection-info
      hide-filter-option
      hide-sort-option
      hide-column-edit-option
      @on-search="updateSearch"
    />
    <!-- Action Part -->
    <div class="action-part d-flex align-items-center flex-row font-inter">
      <div class="selected-count">
        {{ selectedProductsCount }} Selected
      </div>
      <div class="select-order-products ms-4 font-inter">
        <b-dropdown
          class="select-product-dropdown"
          variant="outline-secondary"
          toggle-class="drop-caret-icon"
          menu-class="mt-1"
          right
          no-caret
        >
          <template #button-content>
            <div class="select-btn-content">
              <span>{{ !selectedProductsCount ? 'Select' : 'Actions' }}</span>
              <span class="ms-1 select-caret-icon">
                <b-icon
                  icon="chevron-down"
                  scale="0.6"
                />
              </span>
            </div>
          </template>
          <b-dropdown-item
            :disabled="!!selectedProductsCount"
            @click="$emit('on-select-all')"
          >
            Select All Products
          </b-dropdown-item>
          <b-dropdown-item
            v-if="!isShipmentPage"
            :disabled="!!selectedProductsCount"
            @click="$emit('on-select-ready-to-ship-products')"
          >
            Select Ready To Ship
          </b-dropdown-item>
          <b-dropdown-item
            :disabled="!selectedProductsCount"
            @click="$emit('on-deselect-all')"
          >
            Deselect All
          </b-dropdown-item>
          <b-dropdown-item
            v-if="!isShipmentPage"
            :disabled="!selectedProductsCount || !(readyToshipProductsCount === selectedProductsCount) || getOrder.status !== ORDER_STATUS.CONFIRMED"
            @click="onClickCreateShipment"
          >
            Create Shipment
          </b-dropdown-item>
          <b-dropdown-item
            v-if="!isShipmentPage"
            :disabled="!selectedProductsCount || isPresentCanceledOrShippedProducts"
            @click="onClickCancelStyles"
          >
            Cancel Styles
          </b-dropdown-item>
          <b-dropdown-item
            v-if="isShipmentPage"
            :disabled="!selectedProductsCount"
            @click="onClickUnAssignShipment"
          >
            Unassign Styles
          </b-dropdown-item>
          <b-dropdown-item
            v-if="!isShipmentPage"
            :disabled="!selectedProductsCount || !(readyToshipProductsCount === selectedProductsCount) || getOrder.status !== ORDER_STATUS.CONFIRMED"
          >
            <div class="d-flex justify-content-between align-items-center">
              <div>Assign to Shipment</div>
              <div><b-icon
                icon="chevron-right"
                scale="0.6"
              /></div>
            </div>
            <div
              v-if="shipmentList.length"
              class="child-options"
            >
              <b-dropdown-item
                v-for="shipment in shipmentList"
                :key="shipment._id"
                @click="onClickAssignShipment(shipment._id, shipment.shipmentSeqId)"
              >
                {{ shipment.shipmentSeqId }}
              </b-dropdown-item>
            </div>
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <div
        class="ms-4 d-flex align-items-center cursor-pointer"
        @click="onClickExpandAll"
      >
        <b-img
          :src="collapseIcon"
          :class="{
            'top-arrow': isExpandedAll,
            'down-arrow': !isExpandedAll,
          }"
        />
        <div class="expand-all-label">
          Expand All
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BDropdown, BDropdownItem, BIcon } from 'bootstrap-vue'
import { SET_ALL_PRODUCT_DESELECTED, SET_IS_EXPANDED_ALL_PRODUCTS } from '@/store/modules/product.module'
import collapseIcon from '@/assets/images/svg/collapseicon.svg'
import { KSearchBarSection } from '@kingpin-global/kingpin-ui'
import {
  SET_COMPONENT, SET_COMPONENT_CONTENT, SET_FORM_ACTIONS, SET_SUB_TITLE, SET_TITLE, TOGGLE_SIDEBAR,
} from '@/store/modules/sidebar.module'
import { ORDER_STATUS } from '@/constants'
import SelectedStylesContent from './SelectedStylesContent.vue'
import { SIDE_PANEL_TYPES } from './product-utils'

export default {
  name: 'ProductSearchSection',
  components: {
    BDropdown,
    BDropdownItem,
    BIcon,
    KSearchBarSection,
  },
  props: {
    shipmentList: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      collapseIcon,
      isExpandedAll: false,
      ORDER_STATUS,
    }
  },
  emits: ['on-search', 'on-select-all', 'on-deselect-all', 'on-select-ready-to-ship-products', 'load-order'],
  computed: {
    isShipmentPage() {
      return this.$store.getters.getIsShipmentPage
    },
    getOrder() {
      return this.$store.getters.getEditingOrder
    },
    selectedProductsCount() {
      return this.$store.getters.getSelectedProductsCount
    },
    readyToshipProductsCount() {
      return this.$store.getters.getReadyToshipProductsCount
    },
    isPresentCanceledOrShippedProducts() {
      return this.$store.getters.getIsProductsCanceledOrInShipment
    },
  },
  methods: {
    onClickExpandAll() {
      this.isExpandedAll = !this.isExpandedAll
      this.$store.commit(SET_IS_EXPANDED_ALL_PRODUCTS, this.isExpandedAll)
    },
    updateSearch(searchingText) {
      this.$emit('on-search', searchingText)
    },
    onClickCreateShipment() {
      this.$store.commit(SET_TITLE, 'Creating Shipment')
      this.$store.commit(SET_SUB_TITLE, '')
      this.$store.commit(SET_COMPONENT, SelectedStylesContent)
      this.$store.commit(SET_COMPONENT_CONTENT, { type: SIDE_PANEL_TYPES.CREATE })
      this.$store.commit(SET_FORM_ACTIONS, this.emitActions)
      this.toggleSidebar()
    },
    onClickCancelStyles() {
      this.$store.commit(SET_TITLE, 'Canceling Styles')
      this.$store.commit(SET_SUB_TITLE, '')
      this.$store.commit(SET_COMPONENT, SelectedStylesContent)
      this.$store.commit(SET_COMPONENT_CONTENT, { type: SIDE_PANEL_TYPES.CANCEL })
      this.$store.commit(SET_FORM_ACTIONS, this.emitActions)
      this.toggleSidebar()
    },
    onClickAssignShipment(shipmentObjId, shipmentSeqId) {
      this.$store.commit(SET_TITLE, 'Assigning To Shipment')
      this.$store.commit(SET_SUB_TITLE, '')
      this.$store.commit(SET_COMPONENT, SelectedStylesContent)
      this.$store.commit(SET_COMPONENT_CONTENT, { shipmentObjId, shipmentSeqId, type: SIDE_PANEL_TYPES.ASSIGN })
      this.$store.commit(SET_FORM_ACTIONS, this.emitActions)
      this.toggleSidebar()
    },
    onClickUnAssignShipment() {
      this.$store.commit(SET_TITLE, 'Unassigning From Shipment')
      this.$store.commit(SET_SUB_TITLE, '')
      this.$store.commit(SET_COMPONENT, SelectedStylesContent)
      this.$store.commit(SET_COMPONENT_CONTENT, { type: SIDE_PANEL_TYPES.UNASSIGN })
      this.$store.commit(SET_FORM_ACTIONS, this.emitActions)
      this.toggleSidebar()
    },
    toggleSidebar() {
      this.$store.commit(TOGGLE_SIDEBAR)
    },
    emitActions(action) {
      switch (action) {
        // when click cancel
        case 'cancel': {
          this.toggleSidebar()
          break
        }
        // When API returns success response
        case 'success': {
          this.toggleSidebar()
          this.$store.commit(SET_ALL_PRODUCT_DESELECTED)
          this.$emit('load-order')
          break
        }
        default: {
          break
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import "@/assets/scss/product/products-search-section.scss";
</style>
