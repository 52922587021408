const colorCode = {
  BEIGE: '#d0b084',
  BLUSH: '#DE5D83',
  CAMEL: '#C19A6B',
  CHARCOAL: '#36454F',
  CREAM: '#FFFDD0',
  DENIM: '#1560BD',
  LILAC: '#C8A2C8',
  MULTI: 'linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet)',
  STONE: '#DDC7A7',
  STRIPE: '#008cdd',
  WINE: '#722F37',
  REGULAR: '',
  LONG: '',
}

export const getColorCode = color => {
  if (color && colorCode.hasOwnProperty(color.toUpperCase())) {
    const colorString = color.toUpperCase()
    if (colorCode[colorString] === '') {
      return null
    }
    return colorCode[colorString]
  }
  return color
}
export const classForWhiteColor = color => {
  if (color && color.toLowerCase() === 'white') {
    return 'border-text-color'
  }
}
