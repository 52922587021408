<template>
  <div class="product-styles-content text-start">
    <div class="style-list-content">
      <div class="font-work-sans sidebar-message">
        {{ sidePanelContent[panelType].confirmMessage }}
      </div>
      <div
        v-if="panelType !== SIDE_PANEL_TYPES.CANCEL"
        class="total-qty-values font-inter text-capitalize"
      >
        <div class="product-value">
          <span class="label">Total Units:</span>
          <span class="value">{{ unitsAndValues.totalQuantity }}</span>
        </div>
        <div class="product-value">
          <span class="label">Total Value:</span>
          <span class="value">{{ unitsAndValues.totalPrice }}</span>
        </div>
      </div>
      <div class="d-flex flex-column style-list">
        <div
          v-for="productId in selectedProducts"
          :key="productId"
          class="style-item"
        >
          <div class="font-poppins product-name">
            {{ getProductById(productId).name }}
          </div>
          <div
            v-for="info in getStyleInfo(productId)"
            :key="info.label"
            class="product-value font-inter text-capitalize"
          >
            <span class="label">{{ info.label }}:</span>
            <span class="value">{{ info.value }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex mt-2 flex-row">
      <k-button
        variant="info"
        :disabled="loading"
        block
        @click="onClickConfirm"
      >
        {{ sidePanelContent[panelType].buttonText }}
        <loader-icon v-if="loading" />
      </k-button>
      <k-button
        class="ms-2"
        variant="outline-info"
        :disabled="loading"
        block
        @click="$emit('dynamic-emits', 'cancel')"
      >
        CANCEL
      </k-button>
    </div>
    <kp-modal
      id="shipment_restrict_modal"
      header-text="Create Shipment Warning"
      variant="info"
      size="lg"
      hide-footer
    >
      <p>{{ createShipmentWarningMessage }}</p>
    </kp-modal>
  </div>
</template>

<script>
import KpModal from '@/@core/components/KpModal.vue'
import { apiToastSuccess, apiToastWarning } from '@/@core/utils/toast'
import store from '@/store'
import { UPDATE_ORDER_PRODUCT_STATUS } from '@/store/modules/order.module'
import { ASSIGN_OR_UNASSIGN_SHIPMENT_PRODUCTS } from '@/store/modules/shipment.module'
import { KButton } from '@kingpin-global/kingpin-ui'
import { constants, utils } from '@kingpin-global/kingpin-utils-frontend'
import { VBModal } from 'bootstrap-vue'
import { LoaderIcon } from 'vue-feather-icons'
import { SIDE_PANEL_TYPES } from './product-utils'

const { ORDER_PRODUCT_SHIPMENT_STATUS } = constants
const { formatCurrency } = utils

export default {
  name: 'SelectedStylesContent',
  components: {
    KButton,
    LoaderIcon,
    KpModal,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    componentContent: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      orderId: this.$route.params.orderId,
      loading: false,
      shipmentObjId: this.componentContent.shipmentObjId || null,
      shipmentSeqId: this.componentContent.shipmentSeqId || null,
      panelType: this.componentContent.type || null,
      unitsAndValues: {},
      SIDE_PANEL_TYPES,
      productId: this.componentContent.productId || null,
      shipmentId: this.$route.params.shipmentId,
      createShipmentWarningMessage: '',
    }
  },
  emits: ['dynamic-emits'],
  computed: {
    selectedProducts() {
      if (this.productId) {
        return this.productId
      }
      return this.$store.getters.getSelectedProducts
    },
    orderProducts() {
      return this.$store.getters.getOrderProducts
    },
    currentShipmentSeqId() {
      return this.$store.getters.shipmentSeqId
    },
    sidePanelContent() {
      return {
        [SIDE_PANEL_TYPES.CREATE]: {
          confirmMessage: 'Are you sure that you want to create a shipment from the styles below:',
          buttonText: 'CREATE',
        },
        [SIDE_PANEL_TYPES.CANCEL]: {
          confirmMessage: `Are you sure that you want to cancel the ${this?.selectedProducts?.length} style${this?.selectedProducts?.length > 1 ? 's' : ''} below:`,
          buttonText: 'CONFIRM',
        },
        [SIDE_PANEL_TYPES.ASSIGN]: {
          confirmMessage: `Are you sure that you want to assign the ${this?.selectedProducts?.length} style${this?.selectedProducts?.length > 1 ? 's' : ''} below to the shipment ${this.shipmentSeqId}:`,
          buttonText: 'ASSIGN',
        },
        [SIDE_PANEL_TYPES.UNASSIGN]: {
          confirmMessage: `Are you sure that you want to unassign the ${this?.selectedProducts?.length} style${this?.selectedProducts?.length > 1 ? 's' : ''} below from the shipment ${this.currentShipmentSeqId}:`,
          buttonText: 'UNASSIGN',
        },
      }
    },
  },
  created() {
    this.unitsAndValues = this.getTotalQtyAndValue()
  },
  methods: {
    onClickConfirm() {
      switch (this.panelType) {
        case SIDE_PANEL_TYPES.CREATE: {
          this.createShipment()
          break
        }
        case SIDE_PANEL_TYPES.CANCEL: {
          this.cancelStyles()
          break
        }
        case SIDE_PANEL_TYPES.ASSIGN: {
          this.assignOrUnassignStyles()
          break
        }
        case SIDE_PANEL_TYPES.UNASSIGN: {
          this.assignOrUnassignStyles()
          break
        }
        default:
          break
      }
    },
    getStyleInfo(productId) {
      const product = this.getProductById(productId)
      return [
        { label: 'Style Code', value: product.styleCode },
        { label: 'Line Total Units', value: product.lineTotalQuantity },
        {
          label: 'Line Total Value',
          value: formatCurrency(product.lineTotalPrice, product.currency),
        },
      ]
    },
    getProductById(productId) {
      return this.orderProducts.find(p => p.productId === productId)
    },
    getTotalQtyAndValue() {
      let totalQuantity = null
      let totalPrice = null
      this.selectedProducts.forEach(productId => {
        totalQuantity += this.getProductById(productId).lineTotalQuantity
        totalPrice += this.getProductById(productId).lineTotalPrice
      })
      return {
        totalQuantity,
        totalPrice: formatCurrency(totalPrice, product.currency),
      }
    },
    createShipment() {
      this.loading = true
      const payload = {
        orderId: this.orderId,
        productIds: this.selectedProducts,
      }
      this.$kpRequest({ ...this.$kpEndpoint.shipment.createShipment, payload })
        .then(res => {
          this.loading = false
          this.$emit('dynamic-emits', 'success')
          apiToastSuccess(res.data.message || 'Shipment Created Successfully')
        })
        .catch(err => {
          this.loading = false
          if (err.response.status === 422 && err.response.data?.data?.creditLimitExceeded) {
            this.createShipmentWarningMessage = err.response.data?.data?.message
            this.$bvModal.show('shipment_restrict_modal')
          } else {
            apiToastWarning(err)
          }
        })
    },
    assignOrUnassignStyles() {
      this.loading = true
      const payload = {
        productIds: this.selectedProducts,
      }
      const payloadData = {
        shipmentId: this.shipmentObjId || this.shipmentId,
        productUpdateType: this.panelType,
        payload,
      }
      store.dispatch(ASSIGN_OR_UNASSIGN_SHIPMENT_PRODUCTS, payloadData)
        .then(res => {
          this.loading = false
          this.$emit('dynamic-emits', 'success')
          apiToastSuccess(res.data.message)
        })
        .catch(err => {
          this.loading = false
          apiToastWarning(err)
        })
    },
    async cancelStyles() {
      this.loading = true
      const payload = {
        orderId: this.orderId,
        productIds: this.selectedProducts,
        status: ORDER_PRODUCT_SHIPMENT_STATUS.CANCELLED.LABEL,
      }
      await this.$store
        .dispatch(UPDATE_ORDER_PRODUCT_STATUS, payload)
        .then(res => {
          this.loading = false
          this.$emit('dynamic-emits', 'success')
          apiToastSuccess(res.data.message)
        })
        .catch(err => {
          this.loading = false
          apiToastWarning(err)
        })
    },
  },
}
</script>

<style lang="scss">
@import "@/assets/scss/product/selected-styles-content.scss";
</style>
