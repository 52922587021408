<template>
  <div
    :style="{ background: getStatusTextAndColor(status).colorCode }"
    class="
      product-status-tag
      d-flex
      flex-row
      justify-content-center
      align-items-center
    "
  >
    <span>{{ getStatusTextAndColor(status).text }}</span>
    <span>
      <edit-icon-with-circle
        :color-code="getStatusTextAndColor(status).colorCode"
      />
    </span>
  </div>
</template>

<script>
import EditIconWithCircle from '@/assets/images/svg/EditIconWithCircle.vue'
import { ORDER_PRODUCT_STATUS_COLOR_CODE } from '@/constants'
import { constants } from '@kingpin-global/kingpin-utils-frontend'
import { isProductInShipment } from '@/common-utils'

const { ORDER_PRODUCT_SHIPMENT_STATUS, SHIPMENT_STATUS, SHIPMENT_TEXT } = constants

export default {
  name: 'ProductStatusBadge',
  components: {
    EditIconWithCircle,
  },
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  methods: {
    getStatusTextAndColor(status) {
      const statusContent = {
        text: '',
        colorCode: '',
      }
      // Status for after shipment created
      if (isProductInShipment(status)) {
        statusContent.text = [ORDER_PRODUCT_SHIPMENT_STATUS.SHIPPED.LABEL, SHIPMENT_STATUS.SHIPMENT_CREATED].includes(status) ? ORDER_PRODUCT_SHIPMENT_STATUS.SHIPPED.TEXT : SHIPMENT_TEXT[status]
        statusContent.colorCode = ORDER_PRODUCT_STATUS_COLOR_CODE[ORDER_PRODUCT_SHIPMENT_STATUS.SHIPPED.LABEL]
        return statusContent
      }
      statusContent.text = Object.values(ORDER_PRODUCT_SHIPMENT_STATUS).find(_ => _.LABEL === status).TEXT
      statusContent.colorCode = ORDER_PRODUCT_STATUS_COLOR_CODE[status]
      return statusContent
    },
  },
}
</script>
