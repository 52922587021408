<template>
  <div class="w-100 d-flex justify-content-between">
    <!-- Card 1 -->
    <k-card class="order-info-card">
      <div
        class="order-info-body d-flex flex-column font-inter text-capitalize"
      >
        <div
          v-for="line in orderInfoCard1"
          :key="line.label"
          class="row-items d-flex justify-content-between"
        >
          <div class="label">
            {{ line.label }}:
          </div>
          <div class="value">
            {{ line.value }}
          </div>
        </div>
      </div>
    </k-card>
    <!-- Card 2 -->
    <k-card class="order-info-card">
      <div
        class="order-info-body d-flex flex-column font-inter text-capitalize"
      >
        <div
          v-for="line in orderInfoCard2"
          :key="line.label"
          class="row-items d-flex justify-content-between"
        >
          <div class="label">
            {{ line.label }}:
          </div>
          <div class="value">
            <span>{{ line.value }}</span>
          </div>
        </div>
      </div>
    </k-card>
  </div>
</template>

<script>
import { KCard } from '@kingpin-global/kingpin-ui'
import { ORDER_STATUSES, SHIPMENT_STATUS } from '@/constants'
import { utils } from '@kingpin-global/kingpin-utils-frontend'

const { formatCurrency, getMonthAndYearFromDate } = utils

export default {
  name: 'OrderInfoCards',
  components: {
    KCard,
  },
  props: {
    orderInfo: {
      type: Object,
      default() {
        return {}
      },
    },
    totalQuantities: {
      type: Number,
      default() {
        return 0
      },
    },
    totalPrice: {
      type: Number,
      default() {
        return 0
      },
    },
  },
  computed: {
    isShipmentPage() {
      return this.$store.getters.getIsShipmentPage
    },
    getLabel() {
      return this.isShipmentPage ? 'Shipment' : 'Order'
    },
    orderInfoCard1() {
      const infoCardData = [
        { label: 'Brand Name', value: this.orderInfo.brandName },
        { label: 'Retailer Name', value: this.orderInfo.retailerName },
        { label: 'Collection', value: this.orderInfo.collectionName },
        { label: `${this.getLabel} Status`, value: this.getStatus(this.orderInfo.status) },
        {
          label: `${this.getLabel} Total Value`,
          value: formatCurrency(this.orderInfo.totalPrice, this.orderInfo.currency),
        }]
      if (this.orderInfo.discountInCredit) {
        infoCardData.push({
          label: 'Credit points applied',
          value: formatCurrency(this.orderInfo.discountInCredit, this.orderInfo.currency),
        }, {
          label: `${this.getLabel} Amount after discount`,
          value: formatCurrency((this.orderInfo.totalPrice - (this.orderInfo.discountInCredit || 0)), this.orderInfo.currency),
        })
      }
      infoCardData.push({ label: `${this.getLabel} Items Quantity`, value: this.orderInfo.totalQuantity })
      return infoCardData
    },
    orderInfoCard2() {
      return [
        { label: 'Quantity Of Styles', value: this.orderInfo.products.length },
        {
          label: 'Quantity Of SKUs',
          value: this.getSkusCount(this.orderInfo.products),
        },
        { label: 'Payment Terms', value: this.orderInfo.paymentTerms },
        { label: 'Logistic Terms', value: this.orderInfo.logisticsTerms },
        {
          label: 'Requested Delivery Window',
          value: this.orderInfo.requestedDeliveryDate ? getMonthAndYearFromDate(this.orderInfo.requestedDeliveryDate) : '',
        },
        {
          label: 'Estimated Delivery Window',
          value: this.orderInfo.expectedAvailabilityDate ? getMonthAndYearFromDate(this.orderInfo.expectedAvailabilityDate) : '',
        },
      ]
    },
  },
  methods: {
    getSkusCount(orderProducts) {
      let count = 0
      orderProducts.forEach(product => {
        count += product.items.length
      })
      return count
    },
    getStatus(status) {
      if (this.isShipmentPage) {
        return SHIPMENT_STATUS[status]
      }
      return ORDER_STATUSES[status]
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/@core/scss/vue/components/order-info-cards';
</style>
