<template>
  <div class="product-img-with-checkbox">
    <div
      class="w-100 h-100"
      @click="$emit(EMIT_ONCLICK_EXPAND)"
    >
      <b-img-lazy
        class="product-image"
        :src="imageUrl"
        :alt="alt"
        @error.native="imageLoadError"
      />
    </div>
    <div class="select-product">
      <label class="select-product-checkbox">
        <input
          type="checkbox"
          :checked="selectedProducts.includes(product.productId)"
          @click="$store.commit(SELECT_PRODUCT_TOGGLE, product)"
        >
        <span class="checkmark" />
      </label>
    </div>
  </div>
</template>

<script>
import { BImgLazy } from 'bootstrap-vue'
import { SELECT_PRODUCT_TOGGLE } from '@/store/modules/product.module'
import { EMIT_ONCLICK_EXPAND, imageLoadError } from '../product-utils'

export default {
  name: 'ProductImageWithCheckbox',
  components: {
    BImgLazy,
  },
  props: {
    imageUrl: {
      type: String,
      default() {
        return ''
      },
    },
    alt: {
      type: String,
      default() {
        return ''
      },
    },
    product: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      imageLoadError,
      EMIT_ONCLICK_EXPAND,
      SELECT_PRODUCT_TOGGLE,
    }
  },
  computed: {
    selectedProducts() {
      return this.$store.getters.getSelectedProducts
    },
  },
  emits: [EMIT_ONCLICK_EXPAND],
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/product/product-information/product-img-checkbox.scss";
</style>
