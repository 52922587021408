<template>
  <div>
    <k-card
      class="product-card"
      :class="{'expanded-product-card': isProductExpanded, 'colors-product-card': isColorsExpanded}"
    >
      <div class="d-flex fex-row justify-content-between position-relative">
        <!-- product Image -->
        <product-image-with-checkbox
          :class="{'expanded-image': isProductExpanded, 'colors-image': isColorsExpanded}"
          :image-url="parseProductImage(product)"
          :alt="`${product.name}-${product.productId}`"
          :product="product"
          @on-click-expand="$emit(EMIT_ONCLICK_EXPAND)"
        />

        <!-- product info -->
        <product-info
          :product="product"
          :shipment-list="shipmentList"
          :is-product-expanded="isProductExpanded"
          :is-colors-expanded="isColorsExpanded"
          :color="color"
          @update-total="$emit(UPDATE_TOTAL)"
          @on-click-expand="$emit(EMIT_ONCLICK_EXPAND)"
          @on-style-change-cancelled="$emit(STYLE_STATUS_CHANGE_CANCELLED, $event)"
          @on-style-assign-shipment="$emit(STYLE_ASSIGN_TO_SHIPMENT, $event)"
          @load-shipment="$emit(LOAD_SHIPMENT)"
        />

        <!-- Line price and quantity -->
        <product-line-price-qty
          :class="{'invisible': isColorsExpanded}"
          :product="product"
        />

        <!-- Collapse Icon -->
        <div
          class="
            d-flex
            justify-content-end
            align-items-end
            p-3
          "
          :class="{'invisible': isColorsExpanded}"
          @click="$emit(EMIT_ONCLICK_EXPAND)"
        >
          <div
            v-if="shipmentId"
            class="position-absolute top-0"
          >
            <b-dropdown
              class="mt-1 k-search-dropdown dropdown-custom-class border-0"
              variant="none"
              toggle-class="p-0 border-0"
              menu-class="w-auto"
              no-caret
              right
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                />
              </template>
              <b-dropdown-item @click.stop="onClickUnAssignProduct()">
                Unassign shipment
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <edit-summary-collapse-icon
            class="cursor-pointer"
            :class="{'top-arrow':isProductExpanded}"
          />
        </div>
      </div>
    </k-card>
  </div>
</template>

<script>
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import { KCard } from '@kingpin-global/kingpin-ui'
import EditSummaryCollapseIcon from '@/assets/images/svg/EditSummaryCollapseIcon.vue'
import FeatherIcon from '@/components/feather-icon/FeatherIcon.vue'
import {
  CLEAR_STATE, SET_COMPONENT, SET_COMPONENT_CONTENT, SET_FORM_ACTIONS, SET_TITLE, TOGGLE_SIDEBAR,
} from '@/store/modules/sidebar.module'
import { SET_ALL_PRODUCT_DESELECTED } from '@/store/modules/product.module'
import { utils } from '@kingpin-global/kingpin-utils-frontend'
import {
  EMIT_ONCLICK_EXPAND, parseProductImage, STYLE_STATUS_CHANGE_CANCELLED, STYLE_ASSIGN_TO_SHIPMENT, LOAD_SHIPMENT, UPDATE_TOTAL, SIDE_PANEL_TYPES,
} from './product-utils'
import ProductInfo from './product-information/ProductInfo.vue'
import ProductImageWithCheckbox from './product-information/ProductImageWithCheckbox.vue'
import ProductLinePriceQty from './product-information/ProductLinePriceQty.vue'
import SelectedStylesContent from './SelectedStylesContent.vue'

const { formatCurrency } = utils

export default {
  name: 'ProductDetails',
  components: {
    KCard,
    EditSummaryCollapseIcon,
    ProductImageWithCheckbox,
    ProductInfo,
    ProductLinePriceQty,
    BDropdown,
    BDropdownItem,
    FeatherIcon,
  },
  props: {
    product: {
      type: Object,
      default() {
        return {}
      },
    },
    shipmentList: {
      type: Array,
      default() {
        return []
      },
    },
    isProductExpanded: {
      type: Boolean,
      default() {
        return false
      },
    },
    isColorsExpanded: {
      type: Boolean,
      default() {
        return false
      },
    },
    color: {
      type: String,
      default() {
        return ''
      },
    },
    lineTotalPrice: {
      type: Number,
      default() {
        return 0
      },
    },
    lineTotalQuantity: {
      type: Number,
      default() {
        return 0
      },
    },
  },
  data() {
    return {
      parseProductImage,
      formatCurrency,
      EMIT_ONCLICK_EXPAND,
      STYLE_STATUS_CHANGE_CANCELLED,
      STYLE_ASSIGN_TO_SHIPMENT,
      LOAD_SHIPMENT,
      UPDATE_TOTAL,
      // constant
      reloadkey: 0,
      shipmentId: this.$route.params?.shipmentId || null,
    }
  },
  emits: [EMIT_ONCLICK_EXPAND, STYLE_STATUS_CHANGE_CANCELLED, STYLE_ASSIGN_TO_SHIPMENT, UPDATE_TOTAL],
  methods: {
    onClickUnAssignProduct() {
      this.$store.commit(CLEAR_STATE)
      this.$store.commit(SET_TITLE, 'Unassigning From Shipment')
      this.$store.commit(SET_COMPONENT, SelectedStylesContent)
      this.$store.commit(SET_COMPONENT_CONTENT, { type: SIDE_PANEL_TYPES.UNASSIGN, productId: [this.product.productId] })
      this.$store.commit(SET_FORM_ACTIONS, this.emitActions)
      this.$store.commit(TOGGLE_SIDEBAR)
    },
    emitActions(action) {
      switch (action) {
        // when click cancel
        case 'cancel': {
          this.$store.commit(TOGGLE_SIDEBAR)
          break
        }
        // When API returns success response
        case 'success': {
          this.$store.commit(TOGGLE_SIDEBAR)
          this.$store.commit(SET_ALL_PRODUCT_DESELECTED)
          this.$emit(LOAD_SHIPMENT)
          break
        }
        default: {
          break
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import "@/assets/scss/product/product-details.scss";
</style>
