<template>
  <div
    class="
            d-flex
            flex-column
            justify-content-center
            align-items-center
            line-price-quantity
            font-montserrat
          "
  >
    <div class="line-total">
      Line Total Value:
    </div>
    <div class="line-total">
      {{ formatCurrency(product.lineTotalPrice, product.currency) }}
    </div>
    <div class="line-units">
      Line Total Units: {{ product.lineTotalQuantity }}
    </div>
  </div>
</template>
<script>
import { utils } from '@kingpin-global/kingpin-utils-frontend'

const { formatCurrency } = utils

export default {
  name: 'ProductLinePriceQty',
  props: {
    product: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      formatCurrency,
    }
  },
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/product/product-information/product-price-qty.scss";
</style>
