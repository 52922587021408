<template>
  <div
    v-if="colors.length"
    class="w-100 product-colors"
  >
    <div
      v-for="(color, i) in colors"
      :key="color"
    >
      <product-details
        class="product-color"
        :product="product"
        :is-colors-expanded="true"
        :color="color"
        @update-total="updateTotal"
      />
      <hr
        v-if="colors.length !== i + 1"
        class="hr-line"
      >
    </div>
    <div @click="$emit(EMIT_ONCLICK_EXPAND)">
      <edit-summary-collapse-icon class="top-arrow colors-collapse-icon cursor-pointer" />
    </div>
  </div>
</template>

<script>
import EditSummaryCollapseIcon from '@/assets/images/svg/EditSummaryCollapseIcon.vue'
import ProductDetails from '../ProductDetails.vue'
import { EMIT_ONCLICK_EXPAND, getColors, UPDATE_TOTAL } from '../product-utils'

export default {
  name: 'ProductColors',
  components: {
    ProductDetails,
    EditSummaryCollapseIcon,
  },
  props: {
    product: {
      type: Object,
      default() {
        return null
      },
    },
  },
  data() {
    return {
      colors: [],
      EMIT_ONCLICK_EXPAND,
    }
  },
  emits: [EMIT_ONCLICK_EXPAND, UPDATE_TOTAL],
  created() {
    this.getColorsList()
  },
  methods: {
    getColorsList() {
      const colorObj = getColors(this.product)
      this.colors = colorObj.colors
    },
    updateTotal(lineQuantity) {
      const lineTotalPrice = lineQuantity * this.product.price
      this.$emit(UPDATE_TOTAL, lineQuantity, lineTotalPrice, this.product.productId)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/product/product-information/product-colors.scss";
</style>
