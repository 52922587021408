<template>
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_1255_4419)">
      <rect
        x="4"
        y="4"
        width="20"
        height="20"
        rx="10"
        fill="white"
      />
      <path
        d="M15.0505 10.4883L17.5113 12.9491L12.486 17.9753C12.358 18.1031 12.2037 18.2017 12.034 18.2641L11.9308 18.2973L9.39563 18.9888C9.34652 19.0023 9.29485 19.0036 9.24512 18.9926C9.19538 18.9816 9.14908 18.9586 9.11025 18.9257C9.07143 18.8927 9.04125 18.8507 9.02234 18.8034C9.00343 18.7561 8.99636 18.7049 9.00176 18.6543L9.01176 18.6043L9.70323 16.0696C9.75074 15.8946 9.83577 15.732 9.95206 15.5944L10.0252 15.5144L15.0505 10.4876V10.4883ZM16.0308 9.50855C16.3499 9.19002 16.7802 9.00778 17.231 9.00024C17.6818 8.9927 18.1179 9.16045 18.4474 9.46813C18.777 9.77581 18.9742 10.1994 18.9977 10.6497C19.0211 11.0999 18.8688 11.5417 18.5729 11.8819L18.4916 11.9694L17.9533 12.5071L15.4926 10.0462L16.0308 9.50855Z"
        :fill="colorCode"
      />
      <rect
        x="4.25"
        y="4.25"
        width="19.5"
        height="19.5"
        rx="9.75"
        stroke="#ECF1F4"
        stroke-width="0.5"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_1255_4419"
        x="0"
        y="0"
        width="28"
        height="28"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood
          flood-opacity="0"
          result="BackgroundImageFix"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feComposite
          in2="hardAlpha"
          operator="out"
        />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1255_4419"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1255_4419"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'EditIconWithCircle',
  props: {
    colorCode: {
      type: String,
      default() {
        return '#DA6F29'
      },
    },
  },
}
</script>
