<template>
  <div
    class="d-flex justify-content-between flex-fill text-nowrap product-info"
    :class="{'expanded-product-info': isProductExpanded}"
    @click="$emit(EMIT_ONCLICK_EXPAND)"
  >
    <!-- constent left  -->
    <div class="d-flex flex-column align-items-start left-content">
      <div class="product-name font-poppins">
        {{ product.name }}
      </div>
      <div
        v-if="isProductExpanded"
        class="breadcrumb-items d-flex font-inter"
      >
        <div
          v-for="(item, i) in getBreadcrumbItems"
          :key="item"
          class="d-flex align-items-center text-start"
        >
          <feather-icon
            v-if="i"
            class="chevron-icon-item"
            icon="ChevronRightIcon"
          />
          <span :class="{ 'active-item': getBreadcrumbItems.length === i + 1 }">
            {{ item }}
          </span>
        </div>
      </div>
      <div
        v-for="info in getProductInfo"
        :key="info.label"
        class="product-value font-inter text-capitalize"
      >
        <span class="label">{{ info.label }}:</span>
        <span class="value">{{ info.value }}</span>
      </div>
      <!-- Product Variant -->
      <div class="product-variant-component">
        <product-variant
          v-if="isColorsExpanded"
          :color-with-sizes="parseColorWithSizes(product, color)"
          :product="product"
          @update-total="$emit(UPDATE_TOTAL)"
        />
      </div>
    </div>
    <!-- Content right -->
    <div
      v-if="!isColorsExpanded"
      class="d-flex flex-column align-items-end right-content"
    >
      <div
        v-if="product.status"
        class="font-inter"
      >
        <product-status-badge
          v-if="!product.isEditableProductStatus"
          :status="isShipmentPage ? editingShipment.status : product.status"
        />
        <!-- Dropdown showing style options -->
        <b-dropdown
          v-else
          class="k-menu-option-dropdown reset-toggle-button-style"
          variant="none"
          menu-class="dropdown-menu-center"
          no-caret
        >
          <template #button-content>
            <!-- Product Status Badge -->
            <product-status-badge :status="isShipmentPage ? editingShipment.status : product.status" />
          </template>
          <b-dropdown-item-button
            v-for="option in product.possibleStatusChanges"
            :key="`${option}${product.productId}`"
            class="text-capitalize"
            @click.stop="onClickOption(option, product)"
          >
            {{ getStatusText(option) }}
          </b-dropdown-item-button>
          <b-dropdown-item
            v-if="canShowAssignToShipment"
            :disabled="!shipmentList.length"
          >
            <div class="d-flex justify-content-between align-items-center">
              <div>Assign to Shipment</div>
              <div><b-icon
                icon="chevron-right"
                scale="0.6"
              /></div>
            </div>
            <div
              v-if="shipmentList.length"
              class="child-options"
            >
              <b-dropdown-item
                v-for="shipment in shipmentList"
                :key="shipment._id"
                @click.stop="onClickAssignShipment({
                  shipmentId: shipment._id,
                  shipmentSeqId: shipment.shipmentSeqId,
                  product
                })"
              >
                {{ shipment.shipmentSeqId }}
              </b-dropdown-item>
            </div>
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <div
        v-if="isAvailabilityDatePresent"
        class="product-value font-inter"
      >
        <span class="label">Availability Date:</span>
        <span
          class="value"
        >{{ formattedDate(product.shippingStartDate, "-") }} to
          {{ formattedDate(product.shippingEndDate, "-") }}</span>
      </div>
      <div
        class="product-value colors-count font-inter"
        :class="isProductExpanded ? 'flex-column align-items-end':'flex-row'"
      >
        <div
          v-if="product.isCarton"
          class="d-flex align-items-center"
          :class="isProductExpanded && 'mb-2'"
        >
          <span class="label me-2">Style Quantity: </span>
          <k-form-input
            v-if="isProductExpanded"
            v-model="product.quantity"
            class="edit-input p-0"
            type="number"
            :disabled="isDisabled"
            :class="product.quantity != quantity ? 'red-border':'' "
            @click.stop=""
            @input="$emit(UPDATE_TOTAL)"
          />
          <span v-else> {{ product.quantity }} <span class="mx-2">|</span> </span>
          <k-button
            v-if="product.quantity != quantity && isProductExpanded"
            variant="secondary"
            size="xs"
            :disabled="isLoading"
            @click.stop="updateQuantity"
          >
            <span v-if="isLoading"> <b-spinner class="save-btn-spinner" /> </span>
            <span v-else> Save </span>
          </k-button>
        </div>
        <div class="product-color-wrapper">
          <span> Colors:  </span>
          <div
            v-for="productColors in product.colors"
            :key="productColors.color"
            class="product-color-bg"
            :class="classForWhiteColor(productColors.colorFamily)"
          >
            <div
              v-if="getColorCode(productColors.colorFamily)"
              class="product-color"
              :style="'background:'+getColorCode(productColors.colorFamily)"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="vertical-line" />
  </div>
</template>

<script>
import { KButton, KFormInput } from '@kingpin-global/kingpin-ui'
import { BSpinner } from 'bootstrap-vue'
import { formattedDate } from '@/@core/utils/format'
import FeatherIcon from '@/components/feather-icon/FeatherIcon.vue'
import { ORDER_PRODUCT_SHIPMENT_STATUS } from '@/constants'
import { SET_SINGLE_SELECTED_PRODUCT } from '@/store/modules/product.module'
import { UPDATE_ORDER_PRODUCTS } from '@/store/modules/order.module'
import { classForWhiteColor, getColorCode } from '@/product-color'
import { isProductInShipment } from '@/common-utils'
import { mapState } from 'vuex'
import { apiToastErrorV2, apiToastSuccessV2 } from '@/@core/utils/toast'
import { utils } from '@kingpin-global/kingpin-utils-frontend'
import {
  EMIT_ONCLICK_EXPAND, parseColorWithSizes, STYLE_ASSIGN_TO_SHIPMENT, STYLE_STATUS_CHANGE_CANCELLED, UPDATE_TOTAL,
} from '../product-utils'
import ProductVariant from './ProductVariant.vue'
import ProductStatusBadge from './ProductStatusBadge.vue'

const { formatCurrency } = utils

export default {
  name: 'ProductInfo',
  components: {
    ProductVariant,
    FeatherIcon,
    BSpinner,
    ProductStatusBadge,
    KButton,
    KFormInput,
  },
  props: {
    product: {
      type: Object,
      default() {
        return {}
      },
    },
    isProductExpanded: {
      type: Boolean,
      default() {
        return false
      },
    },
    isColorsExpanded: {
      type: Boolean,
      default() {
        return false
      },
    },
    color: {
      type: String,
      default() {
        return ''
      },
    },
    shipmentList: {
      type: Array,
      default() {
        return []
      },
    },
  },
  emits: [EMIT_ONCLICK_EXPAND, STYLE_STATUS_CHANGE_CANCELLED, UPDATE_TOTAL],
  data() {
    return {
      formattedDate,
      formatCurrency,
      parseColorWithSizes,
      EMIT_ONCLICK_EXPAND,
      STYLE_STATUS_CHANGE_CANCELLED,
      lineTotalQuantity: 0,
      lineTotalPrice: 0,
      colorLevelQuantity: {},
      quantity: this.product.quantity,
      orderId: this.$route.params.orderId,
      isLoading: false,
      getColorCode,
      classForWhiteColor,
      UPDATE_TOTAL,
    }
  },
  computed: {
    isDisabled() {
      return ORDER_PRODUCT_SHIPMENT_STATUS.CANCELLED.LABEL === this.product.status || isProductInShipment(this.product.status)
    },
    isAvailabilityDatePresent() {
      return this.product.shippingStartDate && this.product.shippingEndDate
    },
    getBreadcrumbItems() {
      return [this.product.department, this.product.category, this.product.subCategory]
    },
    getProductInfo() {
      if (this.isProductExpanded) {
        return [
          { label: 'Style Code', value: this.product.styleCode },
          { label: 'Retail Price', value: formatCurrency(this.product.retailPrice, this.product.currency) },
          { label: 'Wholesale Selling Price', value: formatCurrency(this.product.price, this.product.currency) },
          { label: 'Kingpin Purchase Price', value: formatCurrency(this.product.purchasePrice, this.product.currency) },
        ]
      } else if (this.isColorsExpanded) {
        return [
          { label: 'Unique ID', value: this.product.uniqueId },
          { label: 'Wholesale Price', value: formatCurrency(this.product.price, this.product.currency) },
        ]
      }
      return [
        { label: 'Style Code', value: this.product.styleCode },
        { label: 'Wholesale Price', value: formatCurrency(this.product.price, this.product.currency) },
      ]
    },
    isReadyToShipStatus() {
      return this.product.status === ORDER_PRODUCT_SHIPMENT_STATUS.READY_TO_SHIP.LABEL
    },
    canShowAssignToShipment() {
      return this.isReadyToShipStatus && !isProductInShipment(this.product.status)
    },
    isShipmentPage() {
      return this.$store.getters.getIsShipmentPage
    },
    ...mapState({
      editingShipment: state => state.shipments.editingShipment,
    }),
  },
  mounted() {
    this.updateColorTotal()
    this.quantity = this.product.quantity
  },
  methods: {
    getStatusText(option) {
      return Object.values(ORDER_PRODUCT_SHIPMENT_STATUS).find(_ => _.LABEL === option).TEXT || option
    },
    onStyleStateCancelled(productId) {
      this.$emit(STYLE_STATUS_CHANGE_CANCELLED, productId)
    },
    onClickAssignShipment({ shipmentId, shipmentSeqId, product }) {
      this.$store.commit(SET_SINGLE_SELECTED_PRODUCT, product.productId)
      this.$emit(STYLE_ASSIGN_TO_SHIPMENT, { shipmentId, shipmentSeqId })
    },
    updateColorTotal() {
      this.$emit(UPDATE_TOTAL)
    },
    updateQuantity() {
      if (this.product.quantity < 1) {
        apiToastErrorV2('Quantity cannot be 0')
        return
      }
      this.isLoading = true
      const payload = [
        {
          productId: this.product.productId,
          quantity: parseInt(this.product.quantity),
        },
      ]
      this.$store.dispatch(UPDATE_ORDER_PRODUCTS, { orderId: this.orderId, payload })
        .then(res => {
          this.isLoading = false
          apiToastSuccessV2(res.data.message)
          this.$root.$refs.products.loadOrderDetails()
        })
        .catch(err => {
          this.isLoading = false
          apiToastErrorV2(err)
        })
    },
    onClickOption(option, product) {
      switch (option) {
        case ORDER_PRODUCT_SHIPMENT_STATUS.CANCELLED.LABEL:
          this.onStyleStateCancelled(product.productId)
          break
        default:
          break
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/product/product-information/product-info.scss";
</style>
