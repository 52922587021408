import { ORDER_STATUS } from '@/constants'

const disabledStatusOptions = [
  ORDER_STATUS.CONFIRMED,
  ORDER_STATUS.REJECTED,
  ORDER_STATUS.CANCELLED,
  ORDER_STATUS.PENDING_RETAILER,
]

export const isOptionAccessible = orderStatus => disabledStatusOptions.includes(orderStatus)

export const getFilteredProducts = (OrderProducts, page, perPage) => {
  const offset = (page - 1) * perPage
  return OrderProducts.slice(offset, perPage * page)
}
