export const imageLoadError = event => {
  event.target.src = 'https://via.placeholder.com/300/#fff'
}

export const parseProductImage = product => {
  try {
    // eslint-disable-next-line
    console.log('product', product);
    if (product.images.length && product.images[0]) {
      return product.images[0]
    }
    return ''
  } catch (error) {
    // eslint-disable-next-line
    console.error('Error on => parseProductImage', error);
    return ''
  }
}

export const getColors = product => {
  const colors = []
  product.items.forEach(item => {
    if (!colors.includes(item.color)) {
      colors.push(item.color)
    }
  })
  return { colors, count: colors.length }
}

export const PRODUCT_COLOR_LABEL_TEXT = 'Color'

export const parseColorWithSizes = (productObj, color) => {
  const colorWithSizes = [{ label: PRODUCT_COLOR_LABEL_TEXT, value: color }]
  productObj.items.forEach(item => {
    if (item.color === color) {
      colorWithSizes.push({ label: item.size, value: item.value, skuId: item.skuId })
    }
  })
  return colorWithSizes
}

export const EMIT_ONCLICK_EXPAND = 'on-click-expand'

export const SIDE_PANEL_TYPES = {
  CREATE: 'create',
  CANCEL: 'cancel',
  ASSIGN: 'assign',
  UNASSIGN: 'unassign',
}

export const LOAD_SHIPMENT = 'load-shipment'
export const STYLE_ASSIGN_TO_SHIPMENT = 'on-style-assign-shipment'
export const STYLE_STATUS_CHANGE_CANCELLED = 'on-style-change-cancelled'
export const UPDATE_TOTAL = 'update-total'
