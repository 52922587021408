<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.60388 8.03125L9.97374 11.4011L13.3961 7.97872"
      stroke="#90A0B7"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <rect
      x="0.6"
      y="0.6"
      width="18.8"
      height="18.8"
      rx="9.4"
      stroke="#90A0B7"
      stroke-width="1.2"
    />
  </svg>
</template>

<script>
export default {
  name: 'EditSummaryCollapseIcon',
}
</script>
