<template>
  <div
    v-if="colorWithSizes.length"
    class="d-flex flex-row font-inter product-variant-table"
  >
    <div
      v-for="(item, index) in copiedColorWithSizes"
      :key="item.label"
      class="d-flex flex-column product-variant"
    >
      <div class="header-label text-center">
        {{ item.label }}
      </div>
      <div class="cell-value text-center">
        <span
          v-if="item.label === PRODUCT_COLOR_LABEL_TEXT"
          class="color-value"
        >{{
          item.value
        }}</span>
        <k-form-input
          v-else
          v-model="item.value"
          type="number"
          :class="colorWithSizes[index].value != item.value ? 'red-border':''"
          class="text-center size-value p-0"
          :disabled="isDisabled"
          @input="updateLineTotalPrice"
        />
      </div>
    </div>
    <div
      v-if="originalLineTotalQuantity !== product.lineTotalQuantity"
      class="d-flex flex-column product-variant"
    >
      <div class="header-label save-btn-height text-center" />
      <div class="d-flex align-items-center h-100">
        <k-button
          variant="secondary"
          size="xs"
          :loading="isLoading"
          @click="updateOrder"
        >
          <span> Save </span>
        </k-button>
      </div>
    </div>

  </div>
</template>

<script>
import { KButton, KFormInput } from '@kingpin-global/kingpin-ui'
import { UPDATE_ORDER_PRODUCTS } from '@/store/modules/order.module'
import { ORDER_PRODUCT_SHIPMENT_STATUS } from '@/constants'
import { isProductInShipment } from '@/common-utils'
import { apiToastErrorV2, apiToastSuccessV2 } from '@/@core/utils/toast'
import { PRODUCT_COLOR_LABEL_TEXT, UPDATE_TOTAL } from '../product-utils'

export default {
  name: 'ProductVariant',
  components: {
    KFormInput,
    KButton,
  },
  props: {
    colorWithSizes: {
      type: Array,
      default() {
        return []
      },
    },
    product: {
      type: Object,
      default() {
        return null
      },
    },
  },
  data() {
    return {
      PRODUCT_COLOR_LABEL_TEXT,
      orderId: this.$route.params.orderId,
      copiedColorWithSizes: [],
      originalLineTotalQuantity: 0,
      lineTotalQuantity: 0,
      currentColor: null,
      isLoading: false,
    }
  },
  computed: {
    isDisabled() {
      return this.product.isCarton || this.product.status === ORDER_PRODUCT_SHIPMENT_STATUS.CANCELLED.LABEL || isProductInShipment(this.product.status)
    },
  },
  emits: [UPDATE_TOTAL],
  mounted() {
    this.copiedColorWithSizes = JSON.parse(JSON.stringify(this.colorWithSizes))
    this.originalLineTotalQuantity = this.product.lineTotalQuantity
  },
  methods: {
    updateOrder() {
      if (this.product.lineTotalQuantity === 0) {
        apiToastErrorV2('All the sizes cannot be 0')
        return
      }
      this.isLoading = true
      const items = []
      this.copiedColorWithSizes.forEach((color, index) => {
        if (index !== 0) {
          items.push({
            skuId: color.skuId,
            value: parseInt(color.value),
            isCancelled: this.product.status === ORDER_PRODUCT_SHIPMENT_STATUS.CANCELLED.LABEL,
          })
        }
      })
      this.product.items.forEach(item => {
        if (item.color !== this.copiedColorWithSizes[0].value) {
          items.push({
            skuId: item.skuId,
            value: parseInt(item.value),
            isCancelled: this.product.status === ORDER_PRODUCT_SHIPMENT_STATUS.CANCELLED.LABEL,
          })
        }
      })
      const payload = [
        {
          productId: this.product.productId,
          items,
        },
      ]
      this.$store.dispatch(UPDATE_ORDER_PRODUCTS, { orderId: this.orderId, payload })
        .then(res => {
          this.isLoading = false
          apiToastSuccessV2(res.data.message)
          this.$root.$refs.products.loadOrderDetails()
        })
        .catch(err => {
          this.isLoading = false
          apiToastErrorV2(err)
        })
    },
    updateLineTotalPrice() {
      this.copiedColorWithSizes.forEach((item, index) => {
        if (index !== 0) {
          const index = this.product.items.findIndex(productItem => productItem.skuId === item.skuId)
          if (index !== -1) {
            this.product.items[index].value = item.value
          }
        }
      })
      this.$emit(UPDATE_TOTAL)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/product/product-information/product-variant.scss';
</style>
