<template>
  <b-modal
    :id="id"
    :size="size"
    centered
    hide-header-close
    hide-header
    hide-footer
  >
    <div>
      <div
        class="
          pb-1
          border-bottom
          d-flex
          justify-content-between
          font-montserrat
        "
      >
        <span
          class="custom-modal-heading text-dark"
        >
          {{ headerText }}
        </span>
        <feather-icon
          class="close-x-icon"
          icon="XIcon"
          size="24"
          @click="onClickCancel()"
        />
      </div>

      <div class="mt-2 modal-message font-montserrat">
        <slot />
      </div>
      <div
        v-if="!hideFooter"
        class="mt-3"
        :class="`text-${btnAlignment}`"
      >
        <k-button
          :variant="`outline-${variant}`"
          class="me-2"
          :disabled="loading"
          @click="onClickCancel()"
        >
          {{ closeBtnText }}
        </k-button>
        <k-button
          :variant="variant"
          :disabled="loading"
          @click="onClickConfirm()"
        >
          {{ okBtnText }}
          <loader-icon
            v-if="loading"
            class="ms-1"
          />
        </k-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { KButton } from '@kingpin-global/kingpin-ui'
import { BModal } from 'bootstrap-vue'
import { LoaderIcon } from 'vue-feather-icons'
import FeatherIcon from '@/components/feather-icon/FeatherIcon.vue'

export default {
  name: 'KpModal',
  components: {
    BModal,
    KButton,
    LoaderIcon,
    FeatherIcon,
  },

  props: {
    id: {
      type: String,
      default() {
        return 'kp_modal'
      },
    },
    variant: {
      type: String,
      default() {
        return 'primary'
      },
    },
    size: {
      type: String,
      default() {
        return 'md'
      },
    },
    headerText: {
      type: String,
      default() {
        return 'Modal Heading'
      },
    },
    closeBtnText: {
      type: String,
      default() {
        return 'Cancel'
      },
    },
    okBtnText: {
      type: String,
      default() {
        return 'Ok'
      },
    },
    loading: {
      type: Boolean,
      default() {
        return false
      },
    },
    btnAlignment: {
      type: String,
      default() {
        return 'end'
      },
    },
    hideFooter: {
      type: Boolean,
      default() {
        return false
      },
    },
  },
  emits: ['on-click-ok', 'on-click-cancel'],
  methods: {
    onClickCancel() {
      this.$bvModal.hide(this.id)
      this.$emit('on-click-cancel')
    },
    onClickConfirm() {
      this.$emit('on-click-ok')
    },
  },
}
</script>
